<template>
  <div class="top-banners">
    <StCarousel :items-to-show="3" :items-to-scroll="1">
      <template v-if="isLoading">
        <StCarouselItem v-for="i in 3" :key="i">
          <StSkeletonLoader
            bg-color="var(--background-primary)"
            :shimmer-opacity="0.28"
            class="banner"
            height="auto"
            radius="var(--border-radius-150)"
          />
        </StCarouselItem>
      </template>
      <template v-else>
        <StCarouselItem v-for="(banner, index) in banners" :key="index">
          <NuxtI18nLink
            :to="banner.url"
            class="banner"
            :style="`background-image: url(${banner.publicUploadUrl})`"
          />
        </StCarouselItem>
      </template>
    </StCarousel>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { useBanners } from '@st/core/composables/useBanners'

const { banners, isLoading } = useBanners('desktopCasino')
</script>

<style scoped>
.top-banners {
  margin: 0 calc(var(--spacing-100) * -1);
  margin-bottom: var(--spacing-300);
}

.banner {
  cursor: pointer;

  display: flex;
  flex: 1 0 0;

  aspect-ratio: 416/262;
  min-width: 0;

  /* min-height: 262px; */

  background-color: var(--background-primary);
  background-size: cover;
  border-radius: var(--border-radius-150);
}
</style>
